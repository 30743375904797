<template>
  <div v-if="token" class="property-select">
    <CInput
      v-model="search"
      class="property-search"
      label="Select the properties that you post for:"
      placeholder="Search for your property..."
    />
    <div class="property-list">
      <div v-if="loading" class="text-center p-4">
        <CSpinner color="info" class="text-center" />
      </div>
      <template v-else>
        <CInputCheckbox
          v-for="property in filteredProperties"
          :key="property.id"
          :label="property.name"
          :value="property.id"
          :custom="true"
          :checked="selectedProperties.some(e => e.id === property.id)"
          @update:checked="checked => handleCheck(checked, property)"
        />
      </template>
    </div>
    <div class="footer my-1">
      <CLink
        href="https://www.postengine.com/support"
        target="_blank"
      >
        My property isn't on this list
      </CLink>
      <span>{{ selectedProperties.length }} properties selected</span>
    </div>
    <div class="mt-2">
      <CButton
        color="info"
        shape="square"
        class="float-right"
        :disabled="!selectedProperties.length"
        :title="!selectedProperties.length && 'At least one property must be selected'"
        @click="$router.push({ name: 'InstallInstructions', hash: `#${token}` })"
      >
        Next
      </CButton>
    </div>
  </div>
</template>

<script>
import constants from '@/utils/constants'

export default {
  name: 'PropertySelect',
  model: {
    prop: 'selectedProperties',
    event: 'change'
  },
  props: {
    token: {
      type: String,
      required: true
    },
    selectedProperties: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      search: '',
      properties: []
    }
  },
  computed: {
    filteredProperties () {
      return this.properties.filter(property =>
        property.name.toLowerCase().includes(this.search.toLowerCase()))
    }
  },
  watch: {
    token: {
      handler (token) {
        this.fetchProperties(token)
      },
      immediate: true
    }
  },
  methods: {
    /**
     * Fetches the list of properties from the API.
     *
     * @param {String} token - HMAC token
     */
    async fetchProperties (token) {
      if (!token) return
      try {
        this.loading = true
        const axios = this.axios.create({
          baseURL: process.env.VUE_APP_API_URL,
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'HMACToken ' + token
          }
        })

        // Get the total object count from the first response
        const url = '/rooof/properties/'
        const pageSize = 100
        const response = await axios.get(url, { params: { page_size: pageSize } })
        const total = response.data.count
        let data = response.data.results

        // Create and send the remaning requests in parallel
        const numPages = Math.ceil(total / pageSize)
        const promises = []

        for (let page = 2; page <= numPages; page++) {
          promises.push(axios.get(url, { params: { page_size: pageSize, page: page } }))
        }
        const responses = await Promise.all(promises)
        for (const response of responses) {
          data = data.concat(response.data.results)
        }

        // Filter out unknown properties & properties without a non-inactive subscription to automated posting or caas
        this.properties = data.filter(property =>
          property.name !== 'Unknown Property' &&
          property.features_list.some(feature =>
            (feature.name === constants.rooof.Features.POSTING ||
            feature.name === constants.rooof.Features.POSTING_SERVICE) &&
            feature.status !== constants.rooof.Status.INACTIVE
          )
        )
      } catch (e) {
        this.$router.replace({ name: 'InstallInvalid' })
      } finally {
        this.loading = false
      }
    },
    /**
     * Handler for checking/unchecking a property in the list
     *
     * @param {Boolean} checked
     * @param {Object} property
     */
    handleCheck (checked, property) {
      let newSelected = [...this.selectedProperties]
      if (checked) {
        newSelected.push(property)
      } else {
        newSelected = newSelected.filter(selected => selected.id !== property.id)
      }
      this.$emit('change', newSelected)
    }
  }
}
</script>

<style lang="scss" scoped>
.property-select {
  max-width: 700px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  .property-search {
    margin-bottom: 0;
    ::v-deep label {
      font-size: 1rem;
      font-weight: bold;
      color: $gray-800;
    }
    ::v-deep input {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-color: $gray-200;
    }
  }
  .property-list {
    overflow-y: auto;
    padding: 0.5em 1em;
    border: 1px solid $gray-200;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    .custom-checkbox {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    a {
      color: $info;
      text-decoration: underline;
    }
    span {
      color: $gray-800;
      font-size: 0.75rem;
    }
  }
  button {
    border-radius: 3px;
  }
}
</style>
